import { generatePath } from 'react-router-dom'

export const API_METHODS = { POST: 'POST', GET: 'GET', PUT: 'PUT', DELETE: 'DELETE' }

export const routes = {
    publicIndex: { path: '/', label: 'Home' },
    privacyPolicy: { path: '/privacy-policy/', label: 'Privacy Policy' },
    router: { path: '/router/', label: '' },

    authLogin: { path: '/login/', label: 'Login' },
    authLogout: { path: '/logout/', label: 'Logout' },
    authRegister: { path: '/register/', label: 'Register' },
    authPasswordHelp: { path: '/password-help/', label: 'Password Help' },
    authPasswordReset: { path: '/password-reset/', label: 'Password Reset' },
    authWelcome: { path: '/welcome/', label: 'Welcome' },
    authHandler: { path: '/auth-handler/', label: '' },

    // App

    appIndex: { path: '/app/', label: 'Dashboard' },

    appAccountIndex: { path: '/app/accounts/', label: 'Accounts' },
    appAccountView: { path: '/app/accounts/:accountId/', label: 'View' },
    // Fleets
    appFleetIndex: { path: '/app/fleets/', label: 'Fleets' },
    appFleetCreate: { path: '/app/fleets/create/', label: 'Create' },
    appFleetEdit: { path: '/app/fleets/:fleetId/edit/', label: 'Edit' },
    appFleetView: { path: '/app/fleets/:fleetId/', label: 'View' },

    appAccountUserIndex: { path: '/app/users/', label: 'Users' },
    appAccountUserView: { path: '/app/users/:userId/', label: 'View' },

    appTeamIndex: { path: '/app/team/', label: 'Team' },
    appTeamList: { path: '/app/team/list/', label: 'Overview' },
    appTeamView: { path: '/app/team/:teamId/', label: 'View' },
    appTeamCreate: { path: '/app/team/create/', label: 'Create' },

    appTruckIndex: { path: '/app/trucks/', label: 'Fleet' },
    appTruckList: { path: '/app/trucks/list/', label: 'Overview' },
    appTruckView: { path: '/app/trucks/:truckId/', label: 'View' },

    appStationIndex: { path: '/app/stations/', label: 'Stations' },
    appStationView: { path: '/app/stations/:stationId/', label: 'View' },

    appStationPricesIndex: { path: '/app/stations/:stationId/prices/', label: 'Stations Price List' },
    appStationPricesCreate: { path: '/app/stations/:stationId/prices/create/:createStartTime?/:createEndTime?', label: 'Create Price' },
    appStationPricesEdit: { path: '/app/stations/:stationId/prices/:priceId/', label: 'Edit Price' },

    // placeholder routes point to accounts

    appAccountReportIndex: { path: '/app/accounts/', label: 'Reports' },
    appAccountReportView: { path: '/app/accounts/:accountId/', label: 'View' },

    // end comment

    // Admin

    adminIndex: { path: '/admin/', label: 'Dashboard' },

    adminAccountIndex: { path: '/admin/accounts/', label: 'Accounts' },
    adminAccountCreate: { path: '/admin/accounts/create/', label: 'Create' },
    adminAccountEdit: { path: '/admin/accounts/:accountId/edit/', label: 'Edit' },
    adminAccountView: { path: '/admin/accounts/:accountId/', label: 'View' },

    // Admin Account Dashboard
    adminAccountDashboard: { path: '/admin/accounts/:accountId/dashboard/', label: 'Dashboard' },
    adminAccountFleetIndex: { path: '/admin/accounts/:accountId/fleets/', label: 'Fleets' },
    adminAccountFleetCreate: { path: '/admin/accounts/:accountId/fleets/create/', label: 'Create' },
    adminAccountFleetView: { path: '/admin/accounts/:accountId/fleets/:fleetId/', label: 'View' },

    adminReportsIndex: { path: '/admin/reports/', label: 'Reports' },
    adminReportsTransactions: { path: '/admin/reports/transactions/', label: 'Transactions' },
    adminReportsTruckIMEIs: { path: '/admin/reports/truck-imeis/', label: 'Truck IMEIs' },
    adminReportsTruckActivity: { path: '/admin/reports/truck-activity/', label: 'Truck Activity' },
    adminReportsRFIDTags: { path: '/admin/reports/rfid-tags/', label: 'RFID Tags' },
    adminReportsSignalDownload: { path: '/admin/reports/signal-download/', label: 'Signal Download' },

    adminAccountTruckIndex: { path: '/admin/accounts/:accountId/trucks/', label: 'Trucks' },
    adminAccountTruckCreate: { path: '/admin/accounts/:accountId/trucks/create/', label: 'Create' },
    adminAccountTruckEdit: { path: '/admin/accounts/:accountId/trucks/:truckId/edit/', label: 'Edit' },
    adminAccountTruckView: { path: '/admin/accounts/:accountId/trucks/:truckId/', label: 'View' },

    adminAccountReportIndex: { path: '/admin/accounts/:accountId/reports/', label: 'Reports' },
    adminAccountReportView: { path: '/admin/accounts/:accountId/reports/:reportId/', label: 'View' },

    adminAccountStationIndex: { path: '/admin/accounts/:accountId/stations/', label: 'Stations' },
    adminAccountStationCreate: { path: '/admin/accounts/:accountId/stations/create/', label: 'Create' },
    adminAccountStationEdit: { path: '/admin/accounts/:accountId/stations/:stationId/edit/', label: 'Edit' },
    adminAccountStationView: { path: '/admin/accounts/:accountId/stations/:stationId/', label: 'View' },

    adminAccountUserIndex: { path: '/admin/accounts/:accountId/users/', label: 'Users' },
    adminAccountUserCreate: { path: '/admin/accounts/:accountId/users/create/', label: 'Create' },
    adminAccountUserEdit: { path: '/admin/accounts/:accountId/users/:userId/edit/', label: 'Edit' },
    adminAccountUserView: { path: '/admin/accounts/:accountId/users/:userId/', label: 'View' },

    adminCSVTemplatesIndex: { path: '/admin/csv-templates/', label: 'CSV Templates' },
    adminCSVTemplatesCreate: { path: '/admin/csv-templates/create/', label: 'Create' },
    adminCSVTemplatesEdit: { path: '/admin/csv-templates/:csvTemplateId/edit/', label: 'Edit' },
    adminCSVTemplatesView: { path: '/admin/csv-templates/:csvTemplateId/', label: 'View' },

    adminDataTemplateIndex: { path: '/admin/data-templates/', label: 'Data Templates' },
    adminDataTemplateCreate: { path: '/admin/data-templates/create/', label: 'Create' },
    adminDataTemplateEdit: { path: '/admin/data-templates/:dataTemplateId/edit/', label: 'Edit' },
    adminDataTemplateView: { path: '/admin/data-templates/:dataTemplateId/', label: 'View' },

    adminEmissionFormulasIndex: { path: '/admin/emission-formulas/', label: 'Emission Formulas' },
    adminEmissionFormulasCreate: { path: '/admin/emission-formulas/create/', label: 'Create' },
    adminEmissionFormulasEdit: { path: '/admin/emission-formulas/:formulaId/edit/', label: 'Edit' },

    adminFieldIndex: { path: '/admin/fields/', label: 'Fields' },
    adminFieldCreate: { path: '/admin/fields/create/', label: 'Create' },
    adminFieldEdit: { path: '/admin/fields/:fieldId/edit/', label: 'Edit' },
    adminFieldView: { path: '/admin/fields/:fieldId/', label: 'View' },

    adminReportEstopIndex: { path: '/admin/reports/estops/', label: 'E-Stops' },
    adminReportEstopList: { path: '/admin/reports/estops/list/', label: 'Overview' },
    adminReportEstopView: { path: '/admin/reports/estops/:id/', label: 'View' },

    adminTelematicIndex: { path: '/admin/telematics/', label: 'Telematics' },
    adminTelematicCreate: { path: '/admin/telematics/create/', label: 'Create' },
    adminTelematicEdit: { path: '/admin/telematics/:telematicId/edit/', label: 'Edit' },
    adminTelematicView: { path: '/admin/telematics/:telematicId/', label: 'View' },

    adminTruckIndex: { path: '/admin/trucks/', label: 'Fleet' },
    adminTruckList: { path: '/admin/trucks/list/', label: 'Overview' },
    adminTruckCreate: { path: '/admin/trucks/create/', label: 'Create' },
    adminTruckEdit: { path: '/admin/trucks/:truckId/edit/', label: 'Edit' },
    adminTruckView: { path: '/admin/trucks/:truckId/', label: 'View' },

    adminTruckEngineIndex: { path: '/admin/truck-engines/', label: 'Truck Engines' },
    adminTruckEngineList: { path: '/admin/truck-engines/list/', label: 'Overview' },
    adminTruckEngineCreate: { path: '/admin/truck-engines/create/', label: 'Create' },
    adminTruckEngineEdit: { path: '/admin/truck-engines/:truckEngineId/edit/', label: 'Edit' },
    adminTruckEngineView: { path: '/admin/truck-engines/:truckEngineId/', label: 'View' },

    adminTruckMakeModelIndex: { path: '/admin/truck-make-model/', label: 'Truck Make / Models' },
    adminTruckMakeModelList: { path: '/admin/truck-make-model/list/', label: 'Overview' },
    adminTruckModelCreate: { path: '/admin/truck-make-model/create-model/', label: 'Create' },
    adminTruckModelEdit: { path: '/admin/truck-make-model/:truckModelId/edit-model/', label: 'Edit' },
    adminTruckMakeCreate: { path: '/admin/truck-make-model/create-make/', label: 'Create' },
    adminTruckMakeEdit: { path: '/admin/truck-make-model/:truckMakeId/edit-make/', label: 'Edit' },
    adminTruckOffsetsCreate: { path: '/admin/trucks/:truckId/offsets/create/', label: 'Create Offset' },
    adminTruckOffsetsEdit: { path: '/admin/trucks/:truckId/offsets/:offsetId/edit/', label: 'Edit Offset' },
    adminTruckOffsetsIndex: { path: '/admin/trucks/:truckId/offsets/', label: 'Truck Offsets' },

    adminProfileIndex: { path: '/admin/profile/', label: 'Profile' },

    adminStationIndex: { path: '/admin/stations/', label: 'Stations' },
    adminStationCreate: { path: '/admin/stations/create/', label: 'Create' },
    adminStationEdit: { path: '/admin/stations/:stationId/edit/', label: 'Edit' },
    adminStationView: { path: '/admin/stations/:stationId/', label: 'View' },
    adminStationOffsetsCreate: { path: '/admin/stations/:stationId/offsets/create/', label: 'Create Offset' },
    adminStationOffsetsEdit: { path: '/admin/stations/:stationId/offsets/:offsetId/edit/', label: 'Edit Offset' },
    // transactions
    adminStationPricesIndex: { path: '/admin/stations/:stationId/prices/', label: 'Stations Price List' },
    adminStationPricesCreate: { path: '/admin/stations/:stationId/prices/create/', label: 'Create Price' },
    adminStationPricesEdit: { path: '/admin/stations/:stationId/prices/:priceId', label: 'Edit Price' },

    adminTaxonomyIndex: { path: '/admin/taxonomy/', label: 'Taxonomy' },

    adminVendorIndex: { path: '/admin/vendors/', label: 'Vendors' },
    adminVendorList: { path: '/admin/vendors/list/', label: 'Overview' },
    adminVendorCreate: { path: '/admin/vendors/create/', label: 'Create' },
    adminVendorEdit: { path: '/admin/vendors/:vendorId/edit/', label: 'Edit' },
    adminVendorView: { path: '/admin/vendors/:vendorId/', label: 'View' },

    modalSample: { path: '/:pagePath*/m/sample/', label: '' }
}

export const apiResourceEndpoint = (resource, action, id = null, extraPath = '') => {
    let routeObj = {
        url: `/${resource}/`,
        method: API_METHODS.GET
    }
    // adjust the route
    if (action === 'UPDATE' || action === 'VIEW' || action === 'DELETE') {
        routeObj.url = routeObj.url + `${id}/`
    }
    // adjust the medthod
    if (action === 'CREATE' || action === 'UPDATE') {
        routeObj.method = API_METHODS.POST
    } else if (action === 'DELETE') {
        routeObj.method = API_METHODS.DELETE
    }
    // do we need to append any extra stuff to the url
    if (extraPath !== '') {
        routeObj.url = routeObj.url + extraPath + '/'
    }
    return routeObj
}

export const nestedRoutePath = (parent, route, isParent = false) => {
    let nestedRoute = route.substr(parent.length)
    if (isParent) {
        nestedRoute = nestedRoute + '*'
    }
    return nestedRoute
}

export const buildRoutePath = (route, params = {}) => {
    return generatePath(route, params)
}
